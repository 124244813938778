import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  Project,
  WorkedWith,
  Car,
} from "../components/parts"

const Horseroof = () => {
  return (
    <Layout title="Horseroof">
      <Title heading="Horseroof" sub="Everything polo" />
      <Overview
        project="horseroof"
        text={[
          "Horseroof is a directory of everything related to polo - equipment, clubs, holiday destinations, and wellness.",
          "I built the directory, a website where users can customize their equipment, and a marketplace where users can buy and sell polo ponies.",
        ]}
      />
      <Project
        heading="Horseroof"
        sub="Everything polo under one roof"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/horseroof/horseroof-${i}.png`)}
          bordered
        />
      </div>
      <Visit websites={[{ url: "https://horseroof.com/", label: "Website" }]} />
      <Toolset tools={["reactjs", "airtable", "firebase", "lambda"]} />
      <Project
        heading="Online store"
        sub="Find the best for your polo needs"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3, 4].map(i => `/images/horseroof/shop-${i}.png`)}
          bordered
        />
      </div>
      <Visit
        websites={[{ url: "https://shop.horseroof.com/", label: "Web shop" }]}
      />
      <Toolset tools={["shopify"]} />
      <Project
        heading="Polo pony marketplace"
        sub="Connecting you to the best polo ponies"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3, 4].map(i => `/images/horseroof/market-${i}.png`)}
          bordered
        />
      </div>
      <Visit
        websites={[
          { url: "https://aquipolopony.horseroof.com/", label: "Marketplace" },
        ]}
      />
      <Toolset tools={["reactjs", "firebase"]} />
      <Involvement project="horseroof" />
      <WorkedWith project="horseroof" />
    </Layout>
  )
}

export default Horseroof
